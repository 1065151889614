<script setup>
// import roboNationCore from composables
import RoboNationCore from '~/composables/RoboNationCore';
// import useGameStore from store
import useGameStore from '~/store/game';
// import formatMoney from useHelpers
import { formatMoney } from '~/composables/useHelpers';
// import GameSpeed from components
import GameSpeed from '~/components/GameSpeed.vue';

defineProps({
    score: {
        type: String,
        default: '0',
    },
    income: {
        type: String,
        default: '0',
    },
});

const { locale } = useI18n();

// add a duration variable, set it to null
const duration = reactive({
    currentDay: 0,
    currentDayDate: 0,
    totalMonth: 0,
    currentMonth: 0,
    daysPerMonth: 0,
    currentYear: 0,
});

// add a ref for current amount of money, set it to 0
const currentAmountOfMoney = ref(0);

// add a ref for budget
const budget = reactive({});
// add a ref for the difference between income and cost
const monthIncome = ref(0);

/*
    this component is independant. It can display its own datas.
    They came from robonationcore instance.
    There is 3 datas:

    the first one is : 
    - Game duration , an object of 3 datas :
        - current day : getCurrentDate() from robonationcore instance, The amount of days passed since the start of the game
        - current month: currentMonth from robonationcore instance, The current month of the game
        - days per month: daysPerMonth from robonationcore instance, The amount of days in the current month

    these 3 datas alway change, so watch data from roboNationCore and update the duration variable with a template string
    add it now

*/
// watch currentDay (useGameStore().currentDay) from store and update duration variable
const watchCurrentDay = watch(
    () => useGameStore().currentDay,
    () => {
        // get the current day from robonationcore instance with getCurrentDate()
        duration.currentDay = RoboNationCore.instance.getCurrentDate();
        // get the days per month from robonationcore instance with daysPerMonth
        duration.daysPerMonth = RoboNationCore.instance.daysPerMonth;
        // compute the current day date
        duration.currentDayDate = (duration.currentDay % duration.daysPerMonth) + 1;
        // compute the current month
        duration.totalMonth = Math.floor(duration.currentDay / duration.daysPerMonth);
        duration.currentMonth = (duration.totalMonth % 12) + 1;
        // compute the current year
        duration.currentYear = Math.floor(duration.totalMonth / 12) + 1;
        // get the current amount of money from robonationcore instance with getPlayerMoney() with Id player (useGameStore().playerID) in parameters
        currentAmountOfMoney.value = RoboNationCore.instance.getPlayerMoney(useGameStore().playerID);
        // get player budget
        budget.value = RoboNationCore.instance.getPlayerBudget(useGameStore().playerID);
        // compute the difference between income and costs
        monthIncome.value = budget.value.income - budget.value.costs;
    },
    { immediate: true }
);

const watchUpdateMoney = watch(
    () => useGameStore().isUpdatePlayerMoney,
    () => {
        // get the current amount of money from robonationcore instance with getPlayerMoney() with Id player (useGameStore().playerID) in parameters
        currentAmountOfMoney.value = RoboNationCore.instance.getPlayerMoney(useGameStore().playerID);
        // get player budget
        budget.value = RoboNationCore.instance.getPlayerBudget(useGameStore().playerID);
        // compute the difference between income and costs
        monthIncome.value = budget.value.income - budget.value.costs;
    },
    { immediate: true }
);

// unwatch watchCurrentDay on beforeUnmount
onBeforeUnmount(() => {
    watchCurrentDay();
    watchUpdateMoney();
});
</script>

<template>
    <!-- use tailwind for primary font, font bold, display flex, column direction, align on right with a gap of 8px -->
    <div class="c-game-progression flex flex-col items-end gap-2 font-primary font-bold">
        <!-- display current date using data.global.date from i18n file, with parameters : 0: duration.currentYear, 1: duration.currentMonth, 2: duration.currentDayDate
         -->
        <div class="text-140 capitalize text-white time-block">
            <GameSpeed />
            <!-- {{ $t('data.global.date', [duration.currentYear, duration.currentMonth, duration.currentDayDate]) }} -->
            {{ $t('global.year') }} {{ duration.currentYear }}, {{ $t('global.month') }} {{ duration.currentMonth }},
            {{ $t('global.day') }} {{ duration.currentDayDate }}
        </div>
        <div class="text-240 leading-none text-sales-500">
            <!-- Player amount of money -->
            {{ formatMoney(currentAmountOfMoney, locale) }}
        </div>
        <!-- 
            Next row is the monthly sum between income and cost 
            Both values are given in budget object ref (budget.income and budget.cost)
            display the sum with formatMoney and add a back slash between follow by month (use i18n key global.month)
            if the sum is positive, color is 'sales-500' else it's 'secondary-500'
            font size is 120 and leading is none and weight is 400
        -->
        <div
            :class="{
                'text-sales-500': monthIncome >= 0,
                'text-secondary-500': monthIncome < 0,
            }"
            class="text-120 leading-none"
        >
            {{ formatMoney(monthIncome, locale) }} / {{ $t('global.month') }}
        </div>
    </div>
</template>
<style scoped>
    .time-block {
        display: flex;
        align-items: center;
    }
</style>
